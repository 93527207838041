import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "modules",
      "style": {
        "position": "relative"
      }
    }}>{`Modules`}</h1>
    <p>{`Depending on your use-case, you may prefer to import `}<inlineCode parentName="p">{`ts-terraform`}</inlineCode>{`, which contains all functionality in one package, or alternatively you may prefer to only import the modules you need.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Module`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/modules/ts-terraform"
            }}>{`ts-terraform`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Root module, includes (and re-exports) all the other `}<inlineCode parentName="td">{`@ts-terraform/*`}</inlineCode>{` modules`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/modules/ts-terraform/hcl"
            }}>{`@ts-terraform/hcl`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HCL2 parser, powered by Wasm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/modules/ts-terraform/provider"
            }}>{`@ts-terraform/provider`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`gRPC client for Terraform providers, allows interacting with providers directly, bypassing the `}<inlineCode parentName="td">{`terraform`}</inlineCode>{` CLI`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/modules/ts-terraform/provider-aws"
            }}>{`@ts-terraform/provider-aws`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Code-generated, type-safe client for the Terraform AWS provider`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/modules/ts-terraform/registry-client"
            }}>{`@ts-terraform/registry-client`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API client for the Terraform Registry`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      